span.icon {

  display: inline-block;
  height: 1em;
  width: 1em;
  box-sizing: content-box;
  vertical-align: -0.125em;
  background-size: contain;
  margin: 0 .125em;
  fill: #333;

  &:disabled {
    opacity: .5;
  }

  &.heart-off {
  	> svg {
  		fill: #ccc;
  	}
  }

  &.heart-on {
  	> svg {
  		fill: #dd0000;
  	}
  }

}
