.dialog {
	
	// max-width: 380px;
	padding: 60px 30px 30px 30px;
	background: #fff;
	box-shadow: 3px 5px 5px 0px rgba(#333, .1);

	> .text {
		font-size: 20px;
		line-height: 30px;
	}

	> .buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 30px;
	}
	
}
