#image-zoom {
	
	position: relative;
	z-index: 90;
	display: flex;
	width: 100%;
	height: 100%;

	> img {
		
		align-self: flex-start;
		display: block;
		min-width: auto;
		min-height: auto;
		max-width: 100%;
		max-height: 100vh;
		
		background-color: #fff;
		background-size: 30% auto;
	 	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 282.69 228' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23eee' cx='115.3' cy='35.75' r='35.75'/%3E%3Cpath fill='%23eee' d='M188.7,228h-81.34c-10.27,0-16.24-11.86-10.28-20.41l38.69-55.48l42.65-61.2 c5.03-7.22,15.53-7.22,20.56,0l42.64,61.17l38.7,55.51c5.96,8.55-0.02,20.4-10.28,20.4H188.7z'/%3E%3Cpath fill='%23eee' d='M2.48,206.79l55.44-78.81c4.27-6.07,12.64-7.54,18.72-3.29l112.83,78.81 c10.8,7.54,5.46,24.51-7.71,24.51l-168.27,0C2.58,228-3.8,215.71,2.48,206.79z'/%3E%3C/svg%3E");
	  	background-repeat: no-repeat;
	  	background-position: 50%;

	}

	> .navi {
		position: absolute;
		top: 0;
		z-index: 10;
		height: 100%;
		border: none;
		outline: none;
		background: none;
		padding: 0;
		cursor: pointer;
		&.prev {
			left: 0;
		}
		&.next {
			right: 0;
		}
		&:hover, &:focus {
			border: none;
			outline: none;
		}
		> .icon {
			width: 20px;
			height: 20px;
			background-color: rgba(#fff, .75);
			padding: 10px;
		}
	}

}
