#discounts {
	
	padding: 30px 15px 15px 15px;
	background-color: #fff;

	table {
		
		width: 100%;

		tr {
			border-top: solid 1px #ccc;
		}

		th {
			font-size: 12px;
			line-height: 14px;
			white-space: nowrap;
			padding: 5px 15px;
		}
		
		td {
			width: 33.333%;
			padding: 5px 15px;
			white-space: nowrap;
		}

	}

}