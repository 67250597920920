#gallery-shared-text {
  text-align: center;
  line-height: 24px;
  font-family: 'Lora', serif;
  
  hr {
    max-width: 800px;
    margin: 45px auto;
    height: 1px;
    border: none;
    border-top: solid 1px #333;
  }

  > div {
    max-width: 620px;
    margin: 0 auto;
  }

  h1 {
    font-weight: 500;
  }

  a {
    color: #000;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &[href^="mailto:"] {
      text-decoration: none;
    }
  }

  .social-links {
    img {
      display: inline-block;
      height: auto;
    }
    > div {
      margin-bottom: 15px;
      img {
        width: 40px;
        margin: 0 5px;
      }
    }
  }
}
