#modal {

	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100vh;
	background-color: rgba(#fff, 0.75);
	backdrop-filter: blur(3px);

	display: flex;
	justify-content: center;
	align-items: center;

	animation: fade-in .25s ease;
	
	> .window {
		
		position: relative;
		z-index: 20;
		max-width: 100%;
		max-height: 100%;
		
		&.border {
			border: solid 1px #aaa;
		}

		> .close {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 100;
			cursor: pointer;
			width: 20px;
			height: 20px;
			overflow: hidden;
			padding: 10px;
			margin: 0;
			background-color: rgba(#fff, .75);
			&.disabled {
				cursor: not-allowed;
			}
			> svg {
				fill: #333;
			}
		}
		
	}

}

@keyframes fade-in {
	0% { opacity: 0 }
	100% { opacity: 1 }
}
