#project {

	margin-bottom: 30px;

	&.container {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		width: 100%;
		width: 100vw;
		padding-right: 15px;
		background: #fff;
		box-shadow: 0px 5px 5px 0px rgba(#333, .1);
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		flex-shrink: 0;
		
		> .logo {
			max-width: 280px;
			padding: 15px;
			overflow: hidden;
			&.center {
				margin-left: auto;
				margin-right: auto;
			}
			> img {
				display: block;
				max-width: 100%;
				height: auto;
				cursor: pointer;
			}
		}

		> .cart {
			position: relative;
			width: 140px;
			padding: 0 15px;
			font-size: 26px;
			line-height: 36px;
			align-self: center;
			text-align: center;
			cursor: pointer;
			user-select: none;
			> .amount > span {
    			white-space: nowrap;
				transition: color .25s ease;
			}
			> .buy-all {
				position: absolute;
				right: 10px;
				margin-top: 14px;
				padding: 10px;
				font-size: 12px;
				white-space: nowrap;
				line-height: 12px;
			}
		}

	}
	
	.name {
		padding: 0 0 60px 0;
		text-align: center;
		text-transform: uppercase;
		h1, h2 {
			margin: 0;
			font-weight: 400;
			font-size: 30px;
			letter-spacing: 0.15em;
	    	line-height: 38px;
		}
		h2 {
			margin-top: 15px;
			font-size: 24px;
			font-weight: 300;
			line-height: 32px;
		}
	}

	.order {
		text-align: center;
		font-size: 20px;
		line-height: 30px;
		&.top {
			margin: 0 0 30px 0;
		}
		&.bottom {
			margin: 60px 0 0 0;
		}
	}

	.url-sharer {
		margin: 15px 0;
		> input {
			margin-top: 10px;
			padding: 10px 5px;
			width: 320px;
			border: solid 1px #333;
			border-right: none;
			font-size: 16px;
			color: #888;
		}
		> button {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.not-found {
		padding: 0 0 60px 0;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: .15em;
	  	line-height: 38px;
		> p:first-child {
			font-weight: 400;
		}
		a {
			color: #333;
		}
	}

}

@media (max-width: 500px) {

	#project {
		
		&.container {
			width: 100%;
		}

		.header {
			
			> .logo {
				padding: 10px;
			}

			> .cart {
				padding: 10px;
				font-size: 20px;
				line-height: 30px;
			}

		}

		.url-sharer {
			> input {
				width: 75%;
				border-right: solid 1px #333;
			}
			> button {
				margin-top: 15px;
			}
		}

	}
  
}
