* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

html, body {
	font: 16px/1.21 'Open Sans', arial, sans-serif;
	font-weight: 300;
	color: #000;
}

body {
	margin-top: 180px;
}

.black {
	color: #000;
}

.gray {
	color: #ccc;
	> svg {
		fill: #ccc;
	}
}

.red {
	color: #dd0000;
	> svg {
		fill: #dd0000;
	}
}

.green {
	color: #658e7c;
	> svg {
		fill: #658e7c;
	}
}


.mt-15 {
	margin-top: 15px;
}
.mr-15 {
	margin-right: 15px;
}
.mb-15 {
	margin-bottom: 15px;
}

.center {
	text-align: center;
}

button.primary,
button.secondary,
button.green {
	background-color: transparent;
	padding: 15px 30px;
	font-size: 16px;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .25s ease;
	outline: none;
	svg {
		transition: fill .25s ease;
	}
}

button.primary {
	border: solid 1px #333;
	color: #333;
	&:disabled {
		border-color: #aaa;
		color: #aaa;
		cursor: not-allowed;
	}
	&:hover:enabled {
		background-color: #333;
		color: #eee;
		svg {
			fill: #eee;
		}
	}
}

button.secondary {
	border: solid 1px #aaa;
	color: #aaa;
	svg {
		fill: #aaa;
	}
	&:disabled {
		border-color: #ccc;
		color: #ccc;
		cursor: not-allowed;
	}
	&:hover:enabled, &:focus:enabled {
		background-color: #aaa;
		color: #eee;
		svg {
			fill: #eee;
		}
	}
}

button.green {
	border: none;
	color: #fff;
	svg {
		fill: #eee;
	}
	&:disabled {
		background-color: transparent;
		border: solid 1px #aaa;
		color: #aaa;
		cursor: not-allowed;
	}
	&:hover:enabled, &:focus:enabled {
		opacity: .75;
	}
}

button.green {
	background-color: #577052;
}

button.link {
	background: none;
	border: none;
	padding: 10px;
	cursor: pointer;
}

.error-message {
	display: block;
	margin-top: 5px;
	color: #dd0000;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}

@media (max-width: 500px) {

	body {
		margin-top: 160px;
	}

}
