#gallery {

	outline: none;

	.tile {
		position: relative;
		cursor: pointer;
		
		> .image-container {
			position: relative;
			height: 0;
			
			> img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-size: 30% auto;
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 282.69 228' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='%23eee' cx='115.3' cy='35.75' r='35.75'/%3E%3Cpath fill='%23eee' d='M188.7,228h-81.34c-10.27,0-16.24-11.86-10.28-20.41l38.69-55.48l42.65-61.2 c5.03-7.22,15.53-7.22,20.56,0l42.64,61.17l38.7,55.51c5.96,8.55-0.02,20.4-10.28,20.4H188.7z'/%3E%3Cpath fill='%23eee' d='M2.48,206.79l55.44-78.81c4.27-6.07,12.64-7.54,18.72-3.29l112.83,78.81 c10.8,7.54,5.46,24.51-7.71,24.51l-168.27,0C2.58,228-3.8,215.71,2.48,206.79z'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: 50%;
				&[data-original] {
					outline: solid 1px #eee;
				}
				&.fav-animation {
					animation: fav-animation .25s ease;
				}
			}
			
			@keyframes fav-animation {
				0% { opacity: 1; }
    			50% { opacity: .8; }
    			100% { opacity: 1; }
			}

		}

	}

}

.image-select {
	position: absolute;
	z-index: 20;
	bottom: 0;
	right: 0;
	> button {
		border: none;
		background: none;
		cursor: pointer;
		outline: none;
		font-size: 30px;
		line-height: 30px;
		padding: 10px;
		svg {
			filter: drop-shadow(0 0 4px rgba(255, 255, 255, 1));
			transition: fill .25s ease;
		}
		&:disabled {
			cursor: default;
		}
	}
}

.image-share {
	position: absolute;
	z-index: 20;
	top: 0;
	right: 0;
	> button {
		border: none;
		background: none;
		cursor: pointer;
		padding: 10px;
		opacity: .75;
		> span {
			width: 20px;
			height: 20px;
			font-size: 0;
			display: inline-block;
			border: solid 1px #000;
			border-radius: 50%;
			transition: background-color .25s ease;
			filter: drop-shadow(0 0 1px #000);
		}
		> .off {
			background-color: #fff;
		}
		> .on {
			background-color: #739c8d;
		}
		> .loading {
			background-color: #d0d549;
		}
		> .error {
			background-color: #ea3232;
		}
	}
}
