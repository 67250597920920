span.spinner {
	display: inline-block;
	margin: 0 .25em;
	border: .25em solid #658e7c;
	border-left: .25rem solid transparent;
	border-radius: 50%;
	width: 1.25em;
	height: 1.25em;
	vertical-align: bottom;
	animation: spinner-anim 1.2s linear infinite;
	&.standalone {
		display: block;
		margin: 0 auto;
		width: 2em;
		height: 2em;
		border-width: .125em;
	}
}

button.loading > span.spinner {
	width: 15px;
	height: 15px;
}

@keyframes spinner-anim {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
